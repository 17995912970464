<template>
  <div>
    <div :class="{'result-bar-fixed':resultBarFixed}">
      <div class="safe-area flex-row align-items-center justify-between" style="height:50px">
        <div class="flex-row align-items-center">
          <el-image style="width: 24px; height: 24px" :src="columnIcon" fit="contain"></el-image>
          <div class="margin-left-10">
            <el-breadcrumb v-if="q.keyword" separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :replace="true" :to="{ path: '/mall/prodList' }">全部商品</el-breadcrumb-item>
              <el-breadcrumb-item>{{q.keyword}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb v-else-if="q.prodClass" separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :replace="true" :to="{ path: '/mall/prodList' }">全部商品</el-breadcrumb-item>
              <el-breadcrumb-item v-for="(item,index) in q.prodClass" :key="index" :replace="true" :to="index<q.prodClass.length-1?{ path: '/mall/prodList',query:{q:crypto.encrypt({prodClass:q.prodClass.slice(0,index+1)})} }:''">{{item}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb v-else separator-class="el-icon-arrow-right">
              <el-breadcrumb-item  :replace="true" :to="{ path: '/mall/home' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>全部商品</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <div>共 <span class="color-orange bold">{{prods.total}}</span> 件商品</div>
      </div>
    </div>
    <div style="height: 50px;" v-if="resultBarFixed"></div>
    <div class="safe-area">
      
      <div ref="prodList">
        <el-row :gutter="20" type="flex">
          <el-col :span="5" v-for="(item,index) in prods.list" :key="index">
            <div class="product-list-item" @click="$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:item.spid,hd_id:item.hd_id})},blank:true})">
              <div class="pic">
                <el-image class="img flex-column" style="height: 220px;" :src="item.img_url" fit="contain">
                  <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                    <img src="@/assets/images/no-pic.png" height="200px"/>
                  </div>
                </el-image>
                <div class="gwc"><el-button @click.stop="addShoppingCart(item)" type="primary" plain :disabled="$store.state.loginRes.audit_state!=2" circle icon="el-icon-shopping-cart-full"></el-button></div>
              </div>
              <div class="padding-10 flex-auto flex-column">
                <div class="flex-auto">
                  <div class="font-size-16 bold">{{item.spmch}}</div>
                  <div class="color-sub font-size-12 margin-top-5">{{item.shengccj}}</div>
                  <div class="color-sub font-size-12 margin-top-5">{{item.shpgg}}</div>
                </div>
                <div class="flex-row align-items-center justify-between margin-top-5">
                  <div class="color-orange">￥<span class="font-size-20 bold ">{{item.hshsj}}</span></div>
                  <div class="color-sub font-size-12">库存：{{item.pcjcshl_str}}</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div v-if="prods.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
        <div v-if="prods.noMore&&prods.list.length" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
        <el-empty v-if="!prods.list.length&&prods.page==1&&!prods.loading" description="暂无符合条件结果"></el-empty>
      </div>
    </div>
    <el-dialog custom-class="custom-dialog" title="加入购物车"  :visible.sync="addShoppingCartShow" :append-to-body="true" width="1100px" top="15vh">
      <div>
        <AddShoppingCart :q="gwcQuery"></AddShoppingCart>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import columnIcon from "@/assets/images/scdd-icon.png";
import AddShoppingCart from '@/components/AddShoppingCart.vue'
import crypto from "@/common/crypto";
export default {
  components: {AddShoppingCart },
  name: 'Home',
  data() {
    return {
      columnIcon:columnIcon,
      resultBarFixed:false,
      crypto,
      prods:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[],
        total:0
      },
      activeProd:{},
      addShoppingCartShow:false,
      bodyScrollHeight:0
    }
  },
  computed:{
    q(){
      try {
        let q=JSON.parse(crypto.decrypt(this.$route.query.q))
        console.log(q)
        return q
      } catch (error) {
        return {}
      }
    },
    gwcQuery(){
      let q={spid:this.activeProd.spid,hd_id:this.activeProd.hd_id}
      return q
    }
  },
  methods:{
    init(){
      document.removeEventListener("scroll", this.loadMore);
      this.prods={
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[],
        total:0
      }
      this.bodyScrollHeight=0
      this.getData()
    },
    getData(){
      if(this.q.keyword){
        this.getSearchList()
      }else if(this.q.prodClass){
        this.getProdByClassList()
      }else{
        this.getProdList()
      }
    },
    //获取搜索列表
    getSearchList(){
      this.prods.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.prods.page,
        page_size:this.prods.pageSize,
        keyword:this.q.keyword
      }
      this.$instance.get('/main/get_search_product_list',{params})
      .then(res=>{
        this.prods.loading=false
        if(res.data.code==0){
          this.prods.list=this.prods.list.concat(res.data.data.list)
          this.prods.total=this.prods.total=res.data.data.total
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          if(res.data.data.list.length<this.prods.pageSize){
            this.prods.noMore=true
          }else{this.prods.page+=1}
          if(this.prods.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.prods.loading=false
      }); 
    },
    //获取分类商品列表
    getProdByClassList(){
      this.prods.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.prods.page,
        page_size:this.prods.pageSize,
        big_class_name:this.q.prodClass[0],
        sub_class_name:this.q.prodClass[1]||''
      }
      this.$instance.get('/product/get_product_list',{params})
      .then(res=>{
        this.prods.loading=false
        if(res.data.code==0){
          this.prods.list=this.prods.list.concat(res.data.data.list)
          this.prods.total=this.prods.total=res.data.data.total
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          if(res.data.data.list.length<this.prods.pageSize){
            this.prods.noMore=true
          }else{this.prods.page+=1}
          if(this.prods.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.prods.loading=false
      }); 
    },
    //获取推荐商品列表
    getProdList(){
      this.prods.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.prods.page,
        page_size:this.prods.pageSize
      }
      this.$instance.get('/main/get_tj_product_list',{params})
      .then(res=>{
        this.prods.loading=false
        if(res.data.code==0){
          this.prods.list=this.prods.list.concat(res.data.data.list)
          this.prods.total=this.prods.total=res.data.data.total
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          if(res.data.data.list.length<this.prods.pageSize){
            this.prods.noMore=true
          }else{this.prods.page+=1}
          if(this.prods.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.prods.loading=false
      }); 
    },
    //加载更多商品
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.prodList,
        allow:!this.prods.loading&&!this.prods.noMore,
        offset:0,
        callBack:this.getData,
      })
    },
    //加入购物车
    addShoppingCart(item){
      this.activeProd=item
      this.addShoppingCartShow=true
    },
     //页面滚动bar浮动
    handleScroll(){
      if(document.documentElement.scrollTop>170){
        this.resultBarFixed=true
      }else{
        this.resultBarFixed=false
      }
    },
  },
  created(){
    
    this.getData()
  },
  mounted(){
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {
    "$route.query.q":{
      handler(){
        this.init()
      },
      deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.result-bar-fixed{
  position: fixed;
  left: 0;
  right: 0;
  top: 40px;
  background: #f7f7f7;
  z-index: 99;
  box-shadow: 0 10px 10px rgba(0,0,0,0.1);
}
.el-col:nth-child(10n+1),.el-col:nth-child(10n+2) ,.el-col:nth-child(10n+3),.el-col:nth-child(10n+4),.el-col:nth-child(10n+5){
    .product-list-item>div:last-child{
      background:linear-gradient(#fff 0%,#ddeaff 100%)
    }
  }
  .el-col:nth-child(10n+6) ,.el-col:nth-child(10n+7),.el-col:nth-child(10n+8),.el-col:nth-child(10n+9),.el-col:nth-child(10n+10){
    .product-list-item>div:last-child{
      background:linear-gradient(#fff 0%,#ffeddd 100%)
    }
  }
</style>
<style>
  @import '../../assets/css/product-list-item.scss';
</style>
